.nav-btn {
  position: fixed;
  bottom: 8vh;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  stroke: none !important;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  background: #4176FF;
  color: white;
  border: 0;
}

.pagination-info {
  position: fixed;
  bottom: 8vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.nav-btn:hover {
  background: #618cf8;
}

.back-btn {
  left: 10vw
}

.next-btn {
  right: 10vw;
}


@media ( min-width: 500px) {
  /* Show the table header rows and set all cells to display: table-cell */
  .my-custom-breakpoint td,
  .my-custom-breakpoint th,
  .my-custom-breakpoint tbody th,
  .my-custom-breakpoint tbody td,
  .my-custom-breakpoint thead td,
  .my-custom-breakpoint thead th {
    display: table-cell;
    margin: 0;
  }
  /* Hide the labels in each cell */
  .my-custom-breakpoint td .ui-table-cell-label,
  .my-custom-breakpoint th .ui-table-cell-label {
    display: none;
  }
}